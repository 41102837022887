header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px;
  background-color: #666;

}

.left-div h1 a {
  color: #f0f0f0;
}

.left-div {
  flex-grow: 1;
  padding-left: 10px
}

.right-div {
  display: flex;
  gap: 10px;
}

.right-div h3 {
  margin-right: 20px; /* Ajoute une marge de 10px à droite des éléments du menu */
}

.right-div h3 a {
  color: #f0f0f0;
}

.menu-content h4 a {
  color: #f0f0f0;
}

/* Supprimer le soulignement des liens dans l'en-tête */
header a {
  text-decoration: none;
}

p {
  font-size: 1.3em; /* Modifiez la taille de police selon vos besoins */
}

/* Rendre les zones de texte transparentes */
.d, .dd, .d1, .d2, .d3, .d4, .d5, .d6, .d7, .d8, .d9, .d10, .d11, .d12 {
  background-size: 100% 100%; /* Permet à l'image de remplir toute la div */
  background-position: center center; /* Centre l'image de background dans chaque div */
  display: flex; /* Utilisez flexbox pour aligner le contenu */
  justify-content: center; /* Centrer horizontalement le contenu */
  align-items: center; /* Centrer verticalement le contenu */
  flex-direction: column; /* Aligner le contenu en colonne */
  text-align: center; /* Centrer le texte horizontalement */
  margin-top: 10px;  /* Ajouter une marge supérieure de 10 pixels */
  padding: 20px; /* Ajouter un peu de rembourrage pour améliorer l'apparence des zones de texte */
}

/* Supprimer les marges entre les div */
.d, .dd, .d1, .d2, .d3, .d4, .d5, .d6, .d7, .d8, .d9, .d10, .d11, .d12 {
  margin: 0; /* Supprimer les marges pour les div */
  border: none; /* Supprimer les bordures des div */
}

/* Ajuster la taille des div pour créer l'illusion d'une seule image de fond */
.d, .dd, .d1, .d2, .d3, .d4, .d5, .d6, .d7, .d8, .d9, .d10, .d11, .d12 {
  height: 500px; /* Ajuster la hauteur des div selon vos besoins */
}

/* Style des divs après le header */
.d {
  background-image: url("Background/bg1.0.jpg");
}

.d-logo {
  display: flex;
  overflow: hidden; /* Masque tout contenu dépassant de la div */
  width: 500px; /* Ajuste la largeur de la div à 200 pixels (vous pouvez modifier cette valeur selon vos besoins) */
}


.d-logo img {
  width: 100%;
  height: auto; /* Maintenir le ratio hauteur/largeur de l'image */
  max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
  max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
}

.dd {
  background-image: url("Background/bg1.jpg");
}

.container-dd {
  display: flex;
}

.left-div-dd,
.right-div-dd {
  flex: 1;display: flex; /* Ajout de cette propriété pour que les éléments internes soient alignés correctement */
  flex-direction: column; /* Ajout de cette propriété pour que les éléments soient empilés verticalement */
}

.left-div-dd {
  text-align: left;
  margin: 154px 6px 8px 79px; /* Ajuster les marges : haut, droite, bas, gauche */
  /*background-color: rgba(52, 50, 50, 0.023);*/
  color: #f0f0f0;
  align-items: flex-start; /* Ajout de cette propriété pour aligner le contenu en haut */
  border-radius: 5px; /* Ajouter des coins arrondis pour les zones de texte */
}

.right-div-dd {
  text-align: center;
  margin: 100px 20px 100px 20px; /* Ajuster les marges : haut, droite, bas, gauche */
 
}

.right-div-dd img {
  width: 700px; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
  height: auto; /* Garantit que la hauteur est ajustée automatiquement pour conserver le ratio d'aspect */
  max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
  max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
}


.d1 {
  background-image: url("Background/bg2.jpg");
}

.container-d1 {
  display: flex;
  overflow: hidden; /* Masque tout contenu dépassant de la div */
  margin: 5px 5px 5px 5px; /* Ajuster les marges : haut, droite, bas, gauche */
  width: 500px; /* Ajuste la largeur de la div à 200 pixels (vous pouvez modifier cette valeur selon vos besoins) */
  position: relative;
}

.main-image,
.hover-image {
  width: 100%; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
  height: auto;
  max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
  max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
  margin-bottom: 20px;
}

.hover-image {
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
}

.container-d1:hover .main-image {
  opacity: 0; /* Ajoutez cette ligne pour définir l'opacité de l'image principale à 0 lorsqu'elle est survolée */
}

.container-d1:hover .hover-image {
  opacity: 1; /* Ajoutez cette ligne pour définir l'opacité de l'image de survol à 1 lorsqu'elle est survolée */
}

.d2 {
  background-image: url("Background/bg3.jpg");
}

.d3 {
  background-image: url("Background/bg4.0.jpg");
}

.container-d3 {
  display: flex;
  margin-bottom: 20px;
}

.left-div-d3,
.right-div-d3 {
  flex: 1;display: flex; /* Ajout de cette propriété pour que les éléments internes soient alignés correctement */
  flex-direction: column; /* Ajout de cette propriété pour que les éléments soient empilés verticalement */
}

.left-div-d3 {
  text-align: left;
  margin: 83px 32px 90px 87px; /* Ajuster les marges : haut, droite, bas, gauche */
  background-color: rgba(52, 50, 50, 0);
  color: #f0f0f0;
  align-items: flex-start; /* Ajout de cette propriété pour aligner le contenu en haut */
  border-radius: 5px; /* Ajouter des coins arrondis pour les zones de texte */
}

.right-div-d3 {
  text-align: right;
  margin: -120px 4px 652px 8px/* Ajuster les marges : haut, droite, bas, gauche */
 
}

.right-div-d3 img {
  width: 500px; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
  height: auto; /* Garantit que la hauteur est ajustée automatiquement pour conserver le ratio d'aspect */
  max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
  max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
}

.d4 {
  background-image: url("Background/bg4.jpg");
}

.container-d4 {
  display: flex;
  margin: 13px 84px 555px 101px;
}

.left-div-d4,
.right-div-d4 {
  flex: 1;
}

.left-div-d4 {
  text-align: center;
  margin: 100px 20px 100px 20px; /* Ajuster les marges : haut, droite, bas, gauche */
  background-color: rgba(50, 50, 50, 0.021);
  color: #f0f0f0;
  border-radius: 5px; /* Ajouter des coins arrondis pour les zones de texte */
}

.right-div-d4 {
  text-align: center;
  margin: 100px 20px 100px 113px; /* Ajuster les marges : haut, droite, bas, gauche */
  background-color: rgba(50, 50, 50, 0.021);
  color: #f0f0f0;
  border-radius: 5px; /* Ajouter des coins arrondis pour les zones de texte */
}

.d5 {
  background-image: url("Background/bg5.0.jpg");
}

.new-content {
  display: flex;
  flex-direction: row;
  color: #ffffff;
  border-radius: 5px; /* Ajouter des coins arrondis pour les zones de texte */
  text-align: center;
}

.new-list {
  margin: 13px 53px 7px 45px;
  background-color: rgba(0, 0, 0, 0);
  border-radius: 5px; /* Ajouter des coins arrondis pour les zones de texte */
  text-align: left;
}

.phase1 {
  color: rgb(202, 160, 116);
}
.phase2 {
  color: rgb(235, 174, 19);
}
.phase3 {
  color: rgb(229, 89, 19);
}
.phase4 {
  color: rgb(198, 67, 225);
}

.d6 {
  background-image: url("Background/bg5.jpg");
}

.container-d6 {
  display: flex;
  overflow: hidden; /* Masque tout contenu dépassant de la div */
  width: 700px; /* Ajuste la largeur de la div à 200 pixels (vous pouvez modifier cette valeur selon vos besoins) */
  align-items: center;
  justify-content: center;
  height: 100vh; /* Ajuster la hauteur de la div selon vos besoins */
}


.container-d6 img {
  height: auto; /* Maintenir le ratio hauteur/largeur de l'image */
  width: 100%; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
  max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
  max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
}

.d7 {
  background-image: url("Background/bg6.0.jpg");
}

.container-d7 {
  display: flex;
}

.left-div-d7,
.right-div-d7 {
  flex: 1;display: flex; /* Ajout de cette propriété pour que les éléments internes soient alignés correctement */
  flex-direction: column; /* Ajout de cette propriété pour que les éléments soient empilés verticalement */
}

.right-div-d7 {
  text-align: left;
  margin: 641px 26px 280px 8px; /* Ajuster les marges : haut, droite, bas, gauche */
  /*background-color: rgba(52, 50, 50, 0.023);*/
  color: #f0f0f0;
  align-items: flex-start; /* Ajout de cette propriété pour aligner le contenu en haut */
  border-radius: 5px; /* Ajouter des coins arrondis pour les zones de texte */
}

.left-div-d7 {
  margin: 549px 7px 143px 125px; /* Ajuster les marges : haut, droite, bas, gauche */
  position: relative;
 
}

.left-div-d7:hover .main-image {
  opacity: 0; /* Ajoutez cette ligne pour définir l'opacité de l'image principale à 0 lorsqu'elle est survolée */
}

.left-div-d7:hover .hover-image {
  opacity: 1; /* Ajoutez cette ligne pour définir l'opacité de l'image de survol à 1 lorsqu'elle est survolée */
}

.left-div-d7 img {
  width: 500px; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
  height: auto; /* Garantit que la hauteur est ajustée automatiquement pour conserver le ratio d'aspect */
  max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
  max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
}

.d8 {
  background-image: url("Background/bg6.jpg");
}

.container-d8 {
  display: flex;
  overflow: hidden; /* Masque tout contenu dépassant de la div */
  width: 500px; /* Ajuste la largeur de la div à 200 pixels (vous pouvez modifier cette valeur selon vos besoins) */
}


.container-d8 img {
  height: auto; /* Maintenir le ratio hauteur/largeur de l'image */
  width: 100%; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
  max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
  max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
}

.d9 {
  background-image: url("Background/bg7.jpg");
}

.header-d9 {
  text-align: center;
  background-color: rgba(50, 50, 50, 0.021);
  color: white;
  border-radius: 5px; /* Ajouter des coins arrondis pour les zones de texte */
}

.container-d9 {
  display: flex;
  justify-content: space-around; /* Aligner horizontalement avec un espace entre chaque élément */
  padding: 20px;
}

.image-item1 {
  text-align: center;
  color: white;
  position: relative;
}

.main-image1,
.hover-image1 {
  max-width: 100%;
  height: auto;
  max-height: 300px;
  margin-bottom: 20px;
}

.hover-image1 {
  position: absolute;
  top: 0;
  left: 0;
  display: none;
}

.image-item1:hover .hover-image1 {
  display: block;
}

.image-item1 p {
  margin: 0;
}

.d10 {
  background-image: url("Background/bg8.jpg");
}

.d11 {
  background-image: url("Background/bg9.jpg");
}

.container-d11 {
  display: flex;
  flex-direction: column; /* Disposer les éléments en colonne */
  align-items: center; /* Aligner les éléments horizontalement au centre */
}

.right-div-d11 {
  text-align: center; /* Alignez les éléments au centre horizontalement */
  margin: 30px 100px 215px 100px;
  color: white;
}

.right-div-d11 a {
  text-decoration: none;
}

.main-image3{
  width: 100px;
  height: auto;
}

/* CSS pour masquer les images hover au départ */

.main-image2{
  width: 50px;
  height: auto;
}

.header-d11 {
  text-align: center;
  background-color: rgba(50, 50, 50, 0.150);
  color: white;
  border-radius: 5px; /* Ajouter des coins arrondis pour les zones de texte */
}

.left-div-d11 {
  text-align: center;
 
}

.left-div-d11 img {
  width: 500px; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
  height: auto; /* Garantit que la hauteur est ajustée automatiquement pour conserver le ratio d'aspect */
  max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
  max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
}

.d12 {
  background-image: url("Background/bg5.1.jpg");
}

.header-d12 {
  text-align: center;
  background-color: rgba(50, 50, 50, 0.021);
  color: white;
  border-radius: 5px; /* Ajouter des coins arrondis pour les zones de texte */
}

.container-d12 {
  display: flex;
  flex-direction: column;
}

.contain-img {
  height: 500px; /* Maintenir le ratio hauteur/largeur de l'image */
  width: 100%; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
  max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
  max-height: 100%; /*Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
}

.img1 {
  margin: 11px 6px 7px 6px;
  height: 500px; /* Maintenir le ratio hauteur/largeur de l'image */
  width: 100%; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
  max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
  max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
}

/* Style du footer */
footer {
  background-color: #666;
  padding: 30px;
  text-align: center;
}

footer p {
  margin: 30px;
  color: #f0f0f0;
}

/* Styles pour les petits écrans (appareils mobiles) */
@media (max-width: 767px) {
  
  header{
      padding: 5px;
  }

  footer{
      padding: 20px;
  }

  .menu-btn {
      display: block;
      font-size: 24px; /* Ajustez la taille du texte selon vos préférences */
      margin: 5px auto;
      padding: 5px;
      background-color: #33333300;
      color: #fff;
      border: none;
      cursor: pointer;
  }

  h3{
      display: none;
  }

  .menu-content {
      display: none;
  }

  .right-div.show-menu .menu-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
  }

  .d-logo, .container-d1, .container-d11, .container-d6, .container-d4, .container-d12, .header-d4, .contain-img, .new-content, .new-list, .container-d8, .container-d4, .left-div-dd, .right-div-dd, .left-div-d3, .right-div-d3, .left-div-d5, .right-div-d5, .left-div-d7, .right-div-d7, .right-div-d11, .left-div-d11, .left-div-d4, .right-div-d4{
      margin: 20px auto; /* Ajoutez une marge sur les côtés pour espacer les div */
      width: 100%; /* Ajustez la largeur des div à 80% de la largeur de l'écran */
  }
  
  .d-logo img, .right-div-dd img, .right-div-d3 img, .container-d6 img, .img2, .left-div-d7 img, .container-d8 img, .left-div-d11 img, .container-d1 img, .left-div-d4 img, .right-div-d4 img {
      width: 100%; /* Occuper 100% de la largeur de la div parente */
      height: auto; /* Ajuster la hauteur automatiquement pour conserver le ratio d'aspect */
  }
  .d, .dd, .d1, .d3, .d5, .d4, .d7, .d9, .d12{
      height: auto; /* Ajustez la hauteur automatiquement en fonction du contenu */
      background-size: cover; /* Utilisez une couverture pour l'image de fond */
  }

  .container-dd {
      flex-direction: column; /* Changer de disposition pour une colonne sur les petits écrans */
  }
  .right-div-dd, 
  .left-div-dd  {
      width: 100%; /* Assurer que chaque div occupe la largeur totale de l'écran */
      margin-bottom: 20px; /* Ajouter une marge en bas pour séparer les deux div sur les petits écrans */
  }

  .container-d3 {
      flex-direction: column; /* Changer de disposition pour une colonne sur les petits écrans */
  }
  .right-div-d3, 
  .left-div-d3  {   
      width: 100%; /* Assurer que chaque div occupe la largeur totale de l'écran */
      margin-bottom: 20px; /* Ajouter une marge en bas pour séparer les deux div sur les petits écrans */
  }

  .container-d4,
  .new-content {
      flex-direction: column; /* Changer de disposition pour une colonne sur les petits écrans */
  }
  .contain-img,
  .new-list {
      width: 100%; /* Assurer que chaque div occupe la largeur totale de l'écran */
      margin-bottom: 20px; /* Ajouter une marge en bas pour séparer les deux div sur les petits écrans */
  }

  .container-d5 {
      flex-direction: column; /* Changer de disposition pour une colonne sur les petits écrans */
  }

  .container-d7 {
      flex-direction: column; /* Changer de disposition pour une colonne sur les petits écrans */
  }
  .right-div-d7, 
  .left-div-d7  {
      width: 100%; /* Assurer que chaque div occupe la largeur totale de l'écran */
      margin-bottom: 20px; /* Ajouter une marge en bas pour séparer les deux div sur les petits écrans */
  }

  .container-d9 {
      flex-direction: column;
  }

  .image-item1{
      width: 100%; /* Assurer que chaque div occupe la largeur totale de l'écran */
      margin-bottom: 20px; /* Ajouter une marge en bas pour séparer les deux div sur les petits écrans */
  }

  .container-d11 {
      flex-direction: column; /* Changer de disposition pour une colonne sur les petits écrans */
  }
  .left-div-d11,
  .right-div-d11 {
      width: 100%; /* Assurer que chaque div occupe la largeur totale de l'écran */
      margin-bottom: 10px; /* Ajouter une marge en bas pour séparer les deux div sur les petits écrans */
  }

  p {
      font-size: 1.1em; /* Modifiez la taille de police selon vos besoins */
  }

  .image-item1 {
      font-size: 10px; /* Modifiez la taille de police selon vos besoins */
  }

  .container-d9 {
      display: flex;
      justify-content: space-around; /* Aligner horizontalement avec un espace entre chaque élément */
      padding: 0px;
  }

  .left-div-d11 img {
      width: 400px; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
      height: auto; /* Garantit que la hauteur est ajustée automatiquement pour conserver le ratio d'aspect */
  }

  .container-d6 img {
      width: 350px;  /*Ajuster la largeur de l'image à 80% de la largeur de son contenant sur les petits écrans */
      height: auto; /* Ajuster la hauteur automatiquement pour conserver le ratio d'aspect */
  }

  .img2 {
      height: 600px; /* Maintenir le ratio hauteur/largeur de l'image */
      width: auto; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
      max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
      max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
  }
  
  .img1 {
      display: none;
  }

}

/* Styles pour les écrans de taille moyenne (tablettes) */
@media (min-width: 768px) and (max-width: 1023px) {

  header{
      padding: 5px;
  }

  footer{
      padding: 20px;
  }

  .menu-btn {
      display: block;
      font-size: 24px; /* Ajustez la taille du texte selon vos préférences */
      margin: 5px auto;
      padding: 5px;
      background-color: #33333300;
      color: #fff;
      border: none;
      cursor: pointer;
  }

  h3{
      display: none;
  }

  .d-logo, .container-d1, .container-d11, .container-d6, .container-d4, .container-d12, .header-d4, .contain-img, .new-content, .new-list, .container-d8, .container-d4, .left-div-dd, .right-div-dd, .left-div-d3, .right-div-d3, .left-div-d5, .right-div-d5, .left-div-d7, .right-div-d7, .right-div-d11, .left-div-d11, .left-div-d4, .right-div-d4{
      margin: 20px auto; /* Ajoutez une marge sur les côtés pour espacer les div */
      width: 100%; /* Ajustez la largeur des div à 80% de la largeur de l'écran */
  }

  .d-logo img, .right-div-dd img, .right-div-d3 img, .container-d6 img, .img1, .left-div-d7 img, .container-d8 img, .left-div-d11 img, .container-d1 img, .left-div-d4 img, .right-div-d4 img {
      width: 100%; /* Occuper 100% de la largeur de la div parente */
      height: auto; /* Ajuster la hauteur automatiquement pour conserver le ratio d'aspect */
  }
  .d, .dd, .d1, .d3, .d7, .d9, .d12, .d5{
      height: auto; /* Ajustez la hauteur automatiquement en fonction du contenu */
      background-size: cover; /* Utilisez une couverture pour l'image de fond */
  }

  .container-dd {
      flex-direction: column; /* Changer de disposition pour une colonne sur les petits écrans */
  }
  .right-div-dd, 
  .left-div-dd  {
      width: 100%; /* Assurer que chaque div occupe la largeur totale de l'écran */
      margin-bottom: 20px; /* Ajouter une marge en bas pour séparer les deux div sur les petits écrans */
  }

  .container-d5 {
      flex-direction: column; /* Changer de disposition pour une colonne sur les petits écrans */
  }
  .left-div-d5,
  .right-div-d5 {
      width: 100%; /* Assurer que chaque div occupe la largeur totale de l'écran */
      margin-bottom: 20px; /* Ajouter une marge en bas pour séparer les deux div sur les petits écrans */
  }

  .menu-content {
      display: none;
  }

  .right-div.show-menu .menu-content {
      display: flex;
      flex-direction: column;
      gap: 10px;
  }

  p {
      font-size: 1.2em; /* Modifiez la taille de police selon vos besoins */
  }

  .left-div-d11 img {
      width: 400px; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
      height: auto; /* Garantit que la hauteur est ajustée automatiquement pour conserver le ratio d'aspect */
  }

  .img1 {
      height: 100%; /* Maintenir le ratio hauteur/largeur de l'image */
      width: 800px; /* Utilisez un pourcentage pour occuper 100% de la largeur de la div parente */
      max-width: 100%; /* Empêche l'image de dépasser la largeur de la div parente si elle est plus petite */
      max-height: 100%; /* Empêche l'image de dépasser la hauteur de la div parente si elle est plus petite */
  }

  .img2 {
      display: none;
  }

}

/* Styles pour les grands écrans (ordinateurs de bureau) */
@media (min-width: 1024px) {
  .menu-btn {
      display: none; /* Masquer le bouton sur les grands écrans */
  }

  .menu-content {
      display: none;
  }

  .right-div {
      display: flex ; /* Forcer l'affichage de right-div */
      flex-direction: row !important;
      gap: 10px;
  }

  .img2 {
      display: none;
  }

}
